import { Select, Button, Text, LoadingOverlay, Flex } from '@mantine/core';
import { DateTime } from 'luxon';
import {
  useCohortStore,
  useDispensaryStore,
  useParticipantDataStore,
  useUserStateStore,
} from '../../../../store';
import { observer } from 'mobx-react';
import { BaseModal } from '../BaseModal';
import { UserCard } from './UserCard';

export const OnboardModal = observer(
  ({
    userId,
    onUserStateUpdated,
  }: {
    userId: string;
    onUserStateUpdated: () => void;
  }) => {
    const stateStore = useUserStateStore();
    const cohortStore = useCohortStore();
    const dispensaryStore = useDispensaryStore();
    const participantDataStore = useParticipantDataStore();

    const isDisabled =
      stateStore.loading ||
      !stateStore.cohortSelection ||
      !stateStore.dispensarySelection;

    const onOnboardModalClose = () => {
      stateStore.setOnboardModalOpen(false);
      stateStore.setCohortSelection(null);
      stateStore.setDispensarySelection(null);
    };

    const stammDaten = participantDataStore.getStammDaten();

    const userAlreadyExistsWarning = stateStore.userAlreadyExists && (
      <Text c="red">
        Der Nutzer existiert bereits in Cannavigia. Trotzdem anlegen?
      </Text>
    );

    return (
      <BaseModal
        opened={stateStore.onboardModalOpen}
        onClose={onOnboardModalClose}
        title="Teilnehmer annehmen"
        description="Du hast den Teilnehmer akzeptiert. Wähle nun eine Kohorte aus und
          trage einen Cannaviga Code für den Teilnehmer ein."
        iconName="check_circle"
        iconColor="#3D7F53"
        buttons={[
          <Button
            key="submit"
            disabled={isDisabled}
            onClick={() => {
              if (
                !stateStore.cohortSelection ||
                !stateStore.dispensarySelection
              )
                return;

              stateStore.setActive(
                userId,
                stateStore.cohortSelection,
                stateStore.dispensarySelection,
                stammDaten,
                () => {
                  onOnboardModalClose();
                  onUserStateUpdated();
                },
              );
            }}
          >
            Speichern & Akzeptieren
          </Button>,
          <Button key="cancel" variant="outline" onClick={onOnboardModalClose}>
            Abbrechen
          </Button>,
        ]}
        children={
          <Flex direction="column" gap="10px">
            <LoadingOverlay visible={stateStore.loading} />
            <Select
              key="cohortSelection"
              placeholder="Kohorte auswählen"
              label="Kohorte"
              required
              data={cohortStore.cohortsForOnboarding.map((cohort) => ({
                label: `${cohort.id} - Onboardingende: ${DateTime.fromJSDate(cohort.onboardingEndDate).toFormat('dd.MM.yyyy')}`,
                value: cohort.id,
              }))}
              onChange={(value) => stateStore.setCohortSelection(value)}
              value={stateStore.cohortSelection}
            />
            <Select
              key="dispensarieSelection"
              placeholder="Ausgabestelle auswählen"
              label="Ausgabestelle"
              required
              data={dispensaryStore.dispensaries?.map((dispensary) => ({
                label: `${dispensary.name} (${dispensary.associatedUserCount})`,
                value: dispensary.gln,
              }))}
              onChange={(value) => stateStore.setDispensarySelection(value)}
              value={stateStore.dispensarySelection}
            />
            {UserCard(stammDaten)} {userAlreadyExistsWarning}
          </Flex>
        }
      />
    );
  },
);
